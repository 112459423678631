import request from '@/utils/request';

const BASEURL = process.env.VUE_APP_GOOGLE_BASEURL;

const POST = (url, data) =>
  request({
    url,
    baseURL: BASEURL,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });

const GET = (url, params) =>
  request({
    url,
    baseURL: BASEURL,
    method: 'get',
    params,
  });

/**
 * @description: 上传图片到广告账户
 * @param {*} params
 * @return {*}
 */
export const createImageAsset = (data) =>
  request({
    url: '/google/ads/createImageAsset',
    baseURL: BASEURL,
    method: 'post',
    data,
    timeout: 99999,
    headers: {
      'Content-Type': 'multipart/form-data;charset=UTF-8',
    },
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
/**
 * @description: 上传图片到广告账户-单个图片
 * @param {*} params
 * @return {*}
 */
export const createOneImageAsset = (data) =>
  request({
    url: '/google/ads/createOneImageAsset',
    baseURL: BASEURL,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data;charset=UTF-8',
    },
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });

/**
 * @description: 生成推广链接
 * @param {Object} data
 * @return {*}
 */
export const addSaleToSite = (data) => POST('/sale/addSaleToSite', data);

/**
 * @description: 查询广告账户图片列表
 * @param {Object} data
 * @return {*}
 */
export const accountImageAssets = (data) => POST('/google/ads/accountImageAssets', data);

/**
 * @description: 根据推广链接获取推广的商品、站点信息"
 * @param {*} data
 * @return {*}
 */
export const promotionInfo = (params) => GET('/sale/promotionInfo', params);

/**
 * @description: 广告创建 常量 常用地区，语言，号召按钮
 * @param {Object} params
 * @return {*}
 */
export const adConstsMapGG = (params) => GET('/google/ads/adConstsMap', params);

/**
 * @description: 上传YouTube 视频 到广告账户
 * @param {Object} data
 * @return {*}
 */
export const createVideoAsset = (data) => POST('/google/ads/createVideoAsset', data);

/**
 * @description: 创建广告系列并上传
 * @param {Object} data
 * @return {*}
 */
export const createDraftCampaign = (data) => POST('/google/ads/createDraftCampaign', data);

/**
 * @description: 查询广告系列草稿详情
 * @param {Object} params
 * @return {*}
 */
export const draftCampaignDetail = (params) => GET('/google/ads/draftCampaignDetail', params);

/**
 * @description: 修改广告系列草稿
 * @param {Object} data
 * @return {*}
 */
export const saveDraftCampaign = (data) => POST('/google/ads/saveDraftCampaign', data);

/**
 * @description: 复制广告
 * @param {Object} data
 * @return {*}
 */
export const copyAd = (data) =>
  request({
    url: '/google/ads/copyAd',
    baseURL: BASEURL,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    timeout: 60000,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });

/**
 * @description: 查广告组受众
 * @param {Object} data
 * @return {*}
 */
export const adgroupAudienceInfo = (data) => POST('/google/ads/adgroupAudienceInfo', data);

/**
 * @description: 更新广告组受众
 * @param {Object} data
 * @return {*}
 */
export const updateAdgroupAudiences = (data) => POST('/google/ads/updateAdgroupAudiences', data);

/**
 * @description: 查询广告信息
 * @param {Object} data
 * @return {*}
 */
export const adsInfo = (data) => POST('/google/ads/adsInfo', data);

/**
 * @description: 修改广告
 * @param {Object} data
 * @return {*}
 */
export const updateAds = (data) => POST('/google/ads/updateAds', data);

/**
 * @description: 查询站点对应分类
 * @param {Object} data
 * @return {*}
 */
export const siteCategorys = (params) => GET('/sale/siteCategorys', params);

// 检查账户是否已配置API
export const checkAccountSetup = (parmas) => GET('/google/account/check-account-setup', parmas);

// 根据站点ID获取地区
export const getLocation = (params) => GET('/google/ads/location-auto-fill', params);
