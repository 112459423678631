import request from '@/utils/request';

const BASEURL = process.env.VUE_APP_GOOGLE_BASEURL + '/google';

const POST = (url, data) =>
  request({
    url,
    baseURL: BASEURL,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });

const GET = (url, params) =>
  request({
    url,
    baseURL: BASEURL,
    method: 'get',
    params,
  });

/**
 * @description: 获取广告账户标签
 * @param {*} params
 * @return {*}
 */
export const getTags = (params) => GET('/account/accountTags', params);

/**
 * @description: 查询广告账户列表
 * @param {Object} data
 * @return {*}
 */
export const searchAccount = (data) => POST('/account/search', data);

/**
 * @description: 更新广告账户
 * @param {Object} data
 * @return {*}
 */
export const batchUpdate = (data) => POST('/account/batchUpdate', data);
//隐藏广告账号凭证关联
export const hideCredentialAccount = (data) => POST('/account/hideCredentialAccount', data);

/**
 * @description: 修改广告系列预算
 * @param {Object} data
 * @return {*}
 */
export const cmpaignBudget = (data) => POST('/ads/cmpaignBudget', data);

/**
 * @description: 检查广告账户转化目标是否设置
 * @param {*} params
 * @return {*}
 */
// export const checkConversionAction = params => GET('/account/checkConversionAction', params)

/**
 * @description: 映射“广告账户转化目标”
 * @param {*} data
 * @return {*}
 */
export const mapConversionActions = (data) => POST('/account/mapConversionActions', data);

/**
 * @description: 初始化“广告账户转化目标”+“跟踪模板”
 * @param {*} params
 * @return {*}
 */
export const initAdaccount = (params) => GET('/account/initAdaccount', params);
export const initAdaccountConversionOrder = (params) => GET('/account/initAdaccountConversionOrder', params);

///账户转化回传配置
export const setupAccount = (data) => {
  if (data.account_id) {
    return POST('/account/setup-account', data);
  }
  return POST('/account/setup-accounts', data);
};
